/* === VARIUOS GENEAL STYLES === */


.border-right {
    border-right: 1px solid $border;
} 

.tooltip { pointer-events: none; }


footer {
    font-size: 14px;
    color: #485763;
}



.vh-100 {
    min-height: 100vh;
}

.dropdown-menu.show {
    display: block;
    z-index: 99999999999999;
}

.dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #435ea0;
}


.table thead tr th {
    outline: none;
}


/* === TEXT SYLES === */

h1, h2, h3, h4, h5, p, li, a, span, .dataTables_info, label {
    font-family: 'Lato', sans-serif;
    color: $black;
}

h1, h2, h3, h4, h5 {
    font-weight: bold;
}


a {
    color: $purple;
    &:hover {
        color:$purple-hover;
        text-decoration: underline;
    }
}



body {
    background: $bg-grey;
}


/* === FORMS FORMS FORMS === */

textarea:focus, .dropdown-toggle:focus,
textarea.form-control:focus, 
input.form-control:focus, 
input[type=text]:focus, 
input[type=password]:focus, 
input[type=email]:focus, 
input[type=number]:focus, 
[type=text].form-control:focus, 
[type=password].form-control:focus, 
[type=email].form-control:focus, 
[type=tel].form-control:focus, 
[contenteditable].form-control:focus,
{
  box-shadow: inset 0 -1px 0 #ddd;
}

[type=button], [type=reset], [type=submit], button {
    -webkit-appearance: inherit;
}



.form-control {
    font-size: 14px;
    color: #777B7B;
    border: 1px solid #DBE3E3;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    margin: 0;
    outline: none;
    height: inherit;
    &:focus {
        border: 1px solid $purple;
    }
}


.form-control::-webkit-input-placeholder {
    color: $grey;
    opacity: 0.7;
  }



/* === ICONS ICONS ICONS === */

.fa-sort-down {
    position: relative;
    top: -3px;
}

.fa-sort-up {
    position: relative;
    top: 3px;
}




/* === BUTTONS BUTTONS BUTTONS === */

.btn, button {
    border-radius: 3px;
    font-size: 14px;
    border: 0;
}
.btn.btn-primary {
    background: $purple;
    border-radius: 3px ;
    &:hover {
        background: $purple-hover;
    }
    
}
.btn.btn-outlnie {
    border-radius: 3px;
}

.btn-info {
    color: white;
    background: $black;
    border-radius: 3px;
    &:hover {
        background: #2e3b5a;
    }
}




/* === CARD CONTAINER === */

.card-container {
    background: #fff;
    border-width: 0;
    box-shadow: 0 1px 3px rgba(0,0,0,.05), 0 -1px 0 rgba(0,0,0,.015);
    border-radius: .55rem;
    border-radius: .25rem;
    color: red;
    margin-bottom: 1.5rem;
    &.content {
        padding: 50px 80px;
        h1 {
            font-size: 32px;
        }
    }
}


/* === MODALS  === */


.modal-body {
    padding: 40px 30px;
    .label {
        font-size: 14px;
    }
    .msg {
        margin-top: 15px;
    }
}



.alert-secondary {
    color: #777b7b;
    border-radius: 0;
    background-color: #f7f8fa;
    border-top:none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #d6d9dc;
}