.navbar {
    background-color: white;
    box-shadow: 0 1px 3px rgba(0,0,0,.05), 0 -1px 0 rgba(0,0,0,.015);
    .desktop-nav {
        @media screen and (max-width:992px) {
            display: none;
        }
    }
    .mobile-nav {
        @media screen and (min-width:992px) {
            display: none;
        }
    }
    .navbar-brand {
        img {
            height: 35px;     
        }

        span {
            vertical-align: middle;
            padding-left: 7px;
            font-size: 14px;
            font-weight: bold;
            color: #1b2b62;
        } 
    }

    .suggest-startup {
        @media screen and (min-width: 992px) {
            border-left: 1px solid $border;
        }
    }

    .link {
        font-size: 14px;
        padding-top: 4px;
        display: inline-block;
    
    } 
    .navbar-text {
        font-size: 14px;
        padding: 0;
        @media screen and (max-width: 768px) {
           
            span {
                margin-right: 5px;
            }
            img {
                position: relative;
                top: -2px;
            }
        }
        span {
            font-size: 9px;
            display: block;
            color: #010101;
            margin-bottom: 2px;
        }
        img {
            max-width: 80px;
        }
    }    
 
}





