// Variables

$green: #46B857;
$bg-grey: #f7f8fa;
$grey: #b0b7c7;
$black: #485763;
$light-blue: #e6edf7;
$green: #38c172;
$red: #e3342f;
$blue: #4c84ff;
$border: #eceff1;
$grey2: #a8afbb;
$purple: #445ea0;
$purple-hover: #3f517f;