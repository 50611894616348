/* === Top title and dropdown === */
.overall_stats {

    margin: 15px 14px 15px 15px;

    @media screen and (max-width:768px) {
        margin: 35px 0px 0px 0px;
    }

    h5 {
        font-size: 14px;
    }

    .top-filter {
        padding-right: 10px;
       
        h2 {
            font-size: 16px;
        }

    }
   
}

/* === 4 number columns === */

.overall_stats {

    .number-item {

        border-right: 1px solid $border;
        
        &:last-of-type {
            border-right: 0;
        }
        @media screen and (max-width: 992px) {
            border-right: 0;
            border-bottom: 1px solid $border;
            padding-bottom: 30px;
            &:last-of-type {
                border-bottom: 0;
                padding-top: 15px;
            }

        }


        h2 {
            font-size: 32px;
        }

        img {
            position: relative;
            width: 80px;
            height: 60px;
        }

        .explanation {
            color: #647787;
            font-size: 14px;
            margin-bottom: 0;
        }


        .bottom-details {
            border-top: 1px solid $border;
            padding-top: 5px;
            margin-top: 5px;

            p {
                color: #647787;
                font-size: 14px;
                margin-bottom: 0;
            }

            .green {
                color: $green;
                font-weight: 600;

                .feather {
                    color: $green;
                    width: 19px;
                    height: 19px;
                    position: relative;
                    top: -2px;

                }
            }

            .red {
                color: $red;
                font-weight: 600;

                .feather {
                    color: $red;
                    width: 19px;
                    height: 19px;
                    position: relative;
                    top: -2px;

                }
            }
        }

    }

}


.overall_stats {
    .filter-container {
        border-bottom: 1px solid #eceff1;
        padding-bottom: 30px;
        margin-bottom: 15px;
        margin-top: 25px;
        @media screen and (max-width:1200px) {
            padding-bottom: 0px;
            margin-bottom: 15px;
        }
        .stat_legend {
            font-size: 11px;
            color: #adbac7;
        }
        .btn {
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
        }
   
    }
}

.table-filter {
    h5 {
        @media screen and (max-width:768px) {
            margin-bottom: 15px;
        }
    }
    input {
        border-radius: 3px;
        border-top-left-radius: 3px !important;
        border-bottom-left-radius: 3px !important;
    }
    .filter-legend {
        font-size: 12px;
        color: $black;
        position: relative;
        top: 8px;
        margin-right: 10px;
    }
    .btn-outline {
        border: 1px solid #dedfe0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        font-size: 14px;
        color: #6c757d;
        &:hover {
            background: #f2f2f3;
        }
    }
    .dropdown {
        margin-right: 10px;
    }
    .dropdown-toggle {
        font-size: 14px;
    color: #777b7b;
        width: 100%;
        border: 1px solid #dbe3e3;
        border-radius: 3px;
        font-size: 14px;
        background: white;

        &:hover {
            background: #f2f2f3;
        }
    }

    .dropdown-item {
        font-size: 14px;
    }
}