/* === OVERALL STYLE === */


.tableFixHead          { 
    overflow-y: auto; 
    height: 600px;
    @media screen and (max-width:992px) {
        height: 500px;
    }

    thead tr th {
        
        z-index: 99999;
        

    }
}


.table-hover tbody {
    tr:hover {
        background: #f9f9f9;
    }

    td {
        border-right: 1px solid #eceff1;
    }
}



.main-table {
    
    @media screen and (max-width:992px) {

            display: block;
            width: 100%;
            overflow-x: auto;
    

    }
    
    
    
}



.dataTables_info {
    @media screen and (max-width:768px) {
        margin-top: 15px;
        padding-left: 5px;
    }

}


.table-hover tbody {
    tr:hover {
        background: #f9f9f9;
    }

    td {
        border-right: 1px solid #eceff1;
    }
}


.table {
    background-color: white;
    

    a {
        color: $black;

        &.link {
            color: $purple;
        }
    }

    thead {
        background: #FAFBFC;
        border-radius: .55rem;
        border: 1px solid $border;

        th {
            font-size: 11px;
            color: #777B7B;
            box-shadow: 0 -1px 0px -1px $border, 0 1px 0px -1px $border;
            letter-spacing: 0.92px;
            border-top: 0;
            z-index: 999;
        }

        .border-right {
            border-right: 2px solid $border;
        }

        .feather {
            height: 15px;
            width: 15px;
        }

        .fa-question-circle {
            font-size: 12px;
            vertical-align: middle;
        }
    }

    tbody {
        border-radius: .55rem;
        border: 1px solid $border;

    }

    .name {
        font-size: 14px;
        color: $black;
        width: 17%;

        @media screen and (max-width: 768px) {
            font-size: 12px;
        }

        img {
            max-width: 30px;
            max-height: 15px;
        }
    }

    td {
        font-size: 14px;
        color: $black;
     
      
        border-right: 1px solid #eceff1;
    }

}



/* === BORDER STYLES === */


.table {
    background-color: white;

        .border-right {
            border-right: 2px solid $border;
        }

        .feather {
            height: 15px;
            width: 15px;
        }

        .fa-question-circle {
            font-size: 12px;
            vertical-align: middle;
        }
    

    tbody {
        border-radius: .55rem;
        border: 1px solid $border;
    }

}




/* === ADMIN STYLES === */


.table {

    td {

        &.percentege-cell:nth-child(3n+2) {
            border-right: 1px solid #dee2e6;
        }

        &.percentege-cell:last-child {
            border-right: none;
        }

        @media screen and (max-width: 768px) {
            font-size: 12px;

        }
    }
}

.table {

    th,
    td {
        border-top: 1px solid $border;
    }

    .number {
        font-size: 14px;
        font-weight: 400;
        color: #475763;
        min-width: 0px;
    }
}


.dropdown {
    a {
        &:hover {
            text-decoration: underline;
        }
    }
}



.table {
  
    form {
        display: block;
        width: 100%;
    
        clear: both;
        font-weight: 400;
        color: #212529;

        button {
            padding: 0;
            font-size: 16px;
        }
        @media screen and (max-width: 768px) {
            button {
            font-size: 12px;
            }
        }
    }

}




/* === ICONS === */


.table {
    .fas {
        font-size: 18px;
    }

    td {
        &.green {
            color: $green;
            .fas {
                color: $green;
                max-width: 18px;
                font-size: 14px;
                @media screen and (max-width: 768px) {
                    display: none;
                }

            }
        }
        &.red {
            color: $red;
            .fas {
                color: $red;
                max-width: 18px;
                font-size: 14px;
                @media screen and (max-width: 768px) {
                    display: none;
                }

            }

        }
        a {
            .fas {
                &:hover {
                    color: $purple;
                }
            }
        }
    }

}


table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
    cursor: pointer;
    *cursor: hand;
    background-repeat: no-repeat;
    background-position: center right
}


table.dataTable thead .sorting:before {
    content: "\f0dc";
    /* this is your text. You can also use UTF-8 character codes as I do here */
    font-family: FontAwesome;
}

table.dataTable thead .sorting_desc:before {
    content: "\f0d7";
    /* this is your text. You can also use UTF-8 character codes as I do here */
    font-family: FontAwesome;
}

table.dataTable thead .sorting_asc:before {
    content: "\f0d8";
    /* this is your text. You can also use UTF-8 character codes as I do here */
    font-family: FontAwesome;
}




/* === FIXED TD's === */



.table-container {
    // overflow: scroll;

    display: block;
    width: 100%;
    overflow-x: auto;

}



.table {
    thead tr th {
        position: -webkit-sticky;
        /* for Safari */
        position: sticky;
        top: 0;
        background: #fafbfc;
        border-right: 1px solid $border;
        @media screen and (max-width:992px) {
            position: inherit;
            &:nth-child(2) {
                position: sticky;
                top: 0;
            }
        }

    }

    thead tr th:first-child {
        left: 0;
        z-index: 1;
    }


    thead tr th:nth-child(2) {
        left: 0;
        z-index: 1;
    }
}


.table {
    tbody tr td {
        position: -webkit-sticky;
        /* for Safari */
        position: sticky;
        top: 0;
        background: #FAFBFC;
        border: 1px solid $border;
        background-color: white;
        overflow-y: visible;
        overflow-x: visible;

        @media screen and (min-width: 992px) {
            position: inherit;
        }

        &:last-of-type {
            position: inherit;
        }
    }

    tbody tr td:first-child {
        left: 0;
        z-index: 1;

        @media screen and (max-width:992px) {
            background: #fafbfc;


        }
    }

    tbody tr td:nth-child(2) {
        left: 0;
        z-index: 1;

        @media screen and (max-width:992px) {
            background: #fafbfc;
            border-right: 1px solid $border;

            &::after {
                content: '';
                position: absolute;
                background-color: #eceff1;
                width: 1px;
                height: 100%;
                bottom: 0;
                right: -1px;
            }
        }
    }
}

.table {
    thead tr th:first-child {
        left: 0;
        z-index: 1;
    }
}

.table-nobefore::before {
    content: none !important;
}